const people = [
  {
    name: 'Niang Mouhamadou Lamine',
    role: 'GM Executive Search Maroc GM West Africa',
    imageUrl:
      'https://res.cloudinary.com/daog54j96/image/upload/v1691415895/1_jskyva.png',
    bio: 'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    domaines: [
      'Direction générale',
      "conseil d’administration",
      'directeur filiale',
      "administrateurs"
    ],
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/mouhamadou-lamine-niang-66a52270?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAA8G7zIBRfY3dSRZpGd3h2beovI1HJ67Mx0&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BOWkG6cdHQLiTWwNPD97%2BvQ%3D%3D',
  },
  {
    name: 'fajar ibtissam',
    role: 'Senior recruitment consultant',
    imageUrl:
      'https://res.cloudinary.com/daog54j96/image/upload/v1691415887/2_vddpdd.png',
      domaines: [
        'Direction commerciale',
        'Direction Marketing',
        'Business Development',
        'Responsable Grands Comptes'
      ],
    bio: 'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/ibtissam-fajar-196aa0112?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABxIAwIBVs0stHgEITtfnalUL5RQ-5mM7xg&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3B2FPLCUIaTyKvQ9L%2FNJF5ow%3D%3D',
  },
  {
    name: 'barka yazid',
    role: 'Senior recruitment consultant',
    imageUrl:
      'https://res.cloudinary.com/daog54j96/image/upload/v1691415897/4_uebajw.png',
      domaines: [
        'Directeur(ice) Administratif & Financier',
        'directeur comptable',
        'manager audit',
        'Compliance manager',
        'directeur contentieux',
      ],
    bio: 'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/28k?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABRLL_MBpsYErXHQzuMnqlWZvMEtNwmB8PU&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BUM%2FM0MSjRdOX1i2s0YIvlw%3D%3D',
  },
  {
    name: 'abid jehane',
    role: 'senior recruitment consultant',
    imageUrl:
      'https://res.cloudinary.com/daog54j96/image/upload/v1691415889/3_ip0fik.png',
      domaines: [
        'Direction logistiques ',
        'Direction achats ',
        ' Direction approvisionnements',
      ],
    bio: 'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/jehane-abid?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABRGiFsB3FbVGH9SdnFK9Rp0nAQipZ43Iio&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3B8q1E4rHlRzCpZg25uHFQtw%3D%3D',
  },
  // More people...
]

export default function Example() {
  return (
    <div className="py-24 md:py-32 bg-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 ">
        <div className="max-w-2xl xl:col-span-2">
          <h2 className="text-3xl font-bold tracking-tight textAmz sm:text-4xl">Nos chasseurs</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Grâce à notre savoir-faire, notre expertise et notre expérience, nos chasseurs de tête sont des partenaires d'excellence pour votre entreprise. Nous travaillons en étroite collaboration avec vos équipes de manière transparente et confidentielle pour vous aider à identifier rapidement vos futurs Cadres et Dirigeants .
          </p>
        </div>
        <ul role="list" className="-mt-12 space-y-12 divide-y divide-slate-900 xl:col-span-3 ">
          {people.map((person , index) => (
            <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row w-4/5	items-center">
              <img className={index === 0 ? "w-72 flex-none rounded-2xl object-cover mx-auto" : "w-52 flex-none rounded-2xl object-cover mx-auto"} src={person.imageUrl} alt="" />
              <div className={"w-80 flex-auto ml-16"}>
                <h3 className="text-xl font-bold leading-8 tracking-tight textAmz uppercase">{person.name}</h3>
                <p className="text-lg my-2 font-bold leading-7 textAmz capitalize">{person.role}</p>
                <h5 className="text-base font-semibold leading-8 tracking-tight textAmz">Domaine d’interventions</h5>
                <ul className="mt-6 text-base leading-7 text-gray-600">

                  {person.domaines.map((domaine) => (
                    <li key={domaine} className="list-disc">
                      
                      <span className="capitalize">{domaine}</span>
                    </li>)
                  )}
                </ul>
                <ul role="list" className="mt-6 flex gap-x-6">
                  <li>
                    <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                      <span className="sr-only">Email</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
</svg>

                    </a>
                  </li>
                  <li>
                    <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                      <span className="sr-only">LinkedIn</span>
                      <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
