import React from "react";
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'

const pages = [
 
  { name: ' Nos chasseurs', href: '#', current: true },
]
export default function Breadcrumbs() {
    return (
        <nav className="mt-28 md:mt-40 mx-10 flex relative isolate overflow-hidden  pb-16 pt-14 sm:pb-20 bg-white" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a
                  href={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    );

    }