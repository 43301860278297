import React, { useEffect, useState } from 'react';
import { Dialog, Disclosure } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink } from "react-router-dom";

import {
  ArrowPathIcon,
  CheckIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'
import Footer from './Footer'

const navigation = [
  { name: ' Nos chasseurs', href: '/chasseurs' },
  { name: 'nos références', href: '/references' },
  { name: 'missions executives', href: '/' },
  { name: 'contactez-nous', href: '/contact' },
]
const features = [
  {
    name: 'Notre Mission.',
    description: "Xecutive Africa est spécialisée dans le recrutement de cadres supérieurs pour les entreprises de tous les secteurs d'activité. Nous sommes convaincus que les entreprises les plus performantes sont celles qui disposent des équipes les plus compétentes. C'est pourquoi nous mettons tout en œuvre pour identifier les meilleurs talents du marché et les mettre en relation avec les entreprises qui en ont besoin. Nous disposons d'une équipe de chasseurs de têtes expérimentés, qui utilisent des techniques de recherche avancées pour trouver les candidats les plus qualifiés pour chaque poste. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins spécifiques et leur fournir des candidats qui correspondent à la fois à leurs exigences techniques et à leur culture d'entreprise.",
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Notre Expertise.',
    description: "Nous somme distinguées par une approche personnalisée et d’un niveau d’engagement supérieur envers les besoins de nos partenaires. Le recrutement de cadres supérieurs ne peut être efficace que s'il repose sur une compréhension approfondie des besoins de chaque entreprise. C'est pourquoi nous prenons le temps de comprendre les objectifs, les valeurs et la culture de chaque entreprise avec laquelle nous travaillons. Nous sommes également attentifs aux attentes des candidats que nous recrutons, en nous assurant qu'ils sont en mesure de trouver un poste qui correspond à leurs compétences et à leurs aspirations professionnelles. Le chasseur de tête est la première image de votre entreprise vis-à-vis des candidats, il est dont important de confier cette mission délicate à des experts aguerris qui ont le savoir faire et le réseau adéquat",
    icon: LockClosedIcon,
  },
]
const tiers = [
  {
    name: 'Hobby',
    id: 'tier-hobby',
    href: '#',
    priceMonthly: '$19',
    description: "The perfect plan if you're just getting started with our product.",
    features: ['25 products', 'Up to 10,000 subscribers', 'Advanced analytics', '24-hour support response time'],
    featured: false,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    priceMonthly: '$49',
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      'Dedicated support representative',
      'Marketing automations',
      'Custom integrations',
    ],
    featured: true,
  },
]
const faqs = [
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
]
const footerNavigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [logoSrc, setLogoSrc] = useState('https://res.cloudinary.com/daog54j96/image/upload/v1689583572/Rectangle_89_1_wujzms.png');

  // Replace the following objects with your logo sources for different media widths
  const logoSources = {
    small: 'https://res.cloudinary.com/daog54j96/image/upload/v1689583572/Rectangle_89_1_wujzms.png',
    medium: 'https://res.cloudinary.com/daog54j96/image/upload/v1689583572/Rectangle_89_1_wujzms.png',
  };
  useEffect(() => {
    // Function to set the logo source based on the media width
    const setLogoBasedOnWidth = () => {
      const width = window.innerWidth;

      if (width >= 1550) {
        setLogoSrc(logoSources.small);
      } else  {
        setLogoSrc(logoSources.medium);
      } 
    };

    // Initial set based on the current width
    setLogoBasedOnWidth();

    // Update the logo source when the window is resized
    window.addEventListener('resize', setLogoBasedOnWidth);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('resize', setLogoBasedOnWidth);
    };
  }, []);
  return (
    <header className="absolute bg-white inset-x-0 top-0 z-50">
    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div className="flex lg:flex-1">
        <NavLink to="/" className="-m-1.5  p-1.5">
          <span className="sr-only">Xpertise</span>
          <img
          id="logo1"
            className="h-12 w-32 md:h-16 md:w-40 "
            src={logoSrc}
            alt="Xpertise Logo"
          />
        </NavLink>
      </div>
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="hidden lg:flex lg:gap-x-12">
        {navigation.map((item) => (
          <a key={item.name} href={item.href} className="text-lg font-bold leading-6 textAmz uppercase">
            {item.name}
          </a>
        ))}
      </div>
      <div className="hidden lg:flex lg:flex-1 lg:justify-end">
        <NavLink to="https://www.xpertize.africa" className="text-sm font-semibold leading-6 text-white">
        <img
        id="logo2"
            className="h-12 w-40"
            src="https://res.cloudinary.com/daog54j96/image/upload/v1691424046/Xpertize_tpf680.jpg"
            alt="Xpertise Logo"
          />
        </NavLink>
      </div>
    </nav>
    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
      <div className="fixed inset-0 z-50" />
      <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto niceBg px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div className="flex items-center justify-between">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Xecutive Xpertise Africa</span>
            <img
              className="h-8 w-auto"
              src="https://ik.imagekit.io/kv2uqda6y/Rectangle%2042%20(1).svg?updatedAt=1689935901015"
              alt="Xecutive Xpertise Africa Logo"
            />
          </a>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className="-mx-3 block rounded-lg  px-3 py-2 text-lg font-bold leading-7 textAmz hover:bg-gray-50 uppercase"
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
            <div className="py-6">
              
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  </header>
  )
}
