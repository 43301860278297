import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './compoenents/Header';
import Main from './compoenents/Main';
import Section from './compoenents/Section';
import Footer from './compoenents/Footer';
import Landing from './compoenents/Landing';
import Screen2 from './compoenents/Screen2';
import MyComponent from './compoenents/Logo';
import Landingpage from './compoenents/Example';
import Contact from './compoenents/Contact';
import Excel  from './compoenents/Excel';
import Screen3 from './compoenents/Screen3';
import Testimonial1 from './compoenents/Testemonial1';
import GridComponent from './compoenents/Reference';
import App from './App';
import Secondlanding from './compoenents/Secondlanding';
import reportWebVitals from './reportWebVitals';
import LogoComponent from './compoenents/LogoComponent';
import SplashScreen from './compoenents/Splashscreen';
import Example from './compoenents/Test';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
   
   

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
