import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumbs2 from "./Breadcumbs2";
import Testimonial1 from "./Testemonial1";
import Testemonial2 from "./Testimonial2";
import GridComponent from "./Reference";
export default function Screen3() {
    return(
        <div className="bg-white">
            <Header />
            <Breadcrumbs2 />
           <Testimonial1 /> 
           
           <Testemonial2 />
         
              <GridComponent />
            <Footer />
        </div>
    )
}