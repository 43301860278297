import React ,{ useState, useEffect }from 'react';
import Chasseur from './Chasseur';
import Team from './Team';
import Yazid from './Team2';
import Excel from './Excel';
import Example from './Test';
export default function Teamdesktop(){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return(
        <div className='bg-white'>
       
{windowWidth < 1485 ? (
        <Example />
      ) : (
        <div>
         <Chasseur />    
        <Team />
        <div className="flex items-center mt-2 space-x-4  p-4 rounded-md">
            </div>
        <Yazid  className="mt-2"/>
        </div>
      )}
           
        </div>
    ) ; 
}