import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SplashScreen from "./compoenents/Splashscreen";
import Landing from "./compoenents/Landing";
import Screen2 from "./compoenents/Screen2";
import Screen3 from "./compoenents/Screen3";
import Contact from "./compoenents/Contact";
import Secondlanding from "./compoenents/Secondlanding";
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate the loading process (remove this in your actual implementation)
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const router = createBrowserRouter([
    {
      path: "/",
      element:  <Secondlanding />,
    },
    {
      path: "/chasseurs",
      element: <Screen2/>,
    },
    {
      path: "/references",
      element: <Screen3/>,
    },
    {
      path: "/contact",
      element: <Contact/>,
    },
  ]);
  return (
    <RouterProvider router={router}/>
     
  );
}

export default App;
