/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useState, useCallback } from 'react';

import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

import MyComponent from './Logocontact'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Notification from './Notification';
import Error from './Error';
import Header from './Header';
function objectToFormData(obj) {
  const formData = new FormData();

  for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
          formData.append(key, obj[key]);
      }
  }

  return formData;
}
export default function Contact() {
  const [captchaValue, setCaptchaValue] = useState(true);
  const [show, setShow] = useState(false)
  const  [showError, setShowError] = useState(false)
  const apiKey = process.env.HUBSPOT_API_KEY;
  const data = {
    "properties": {
      "email": "",
      "firstname": "",
      "lastname": "",
      "phone": "",
      "company": "",
      "message": "",
      "website": "xecutive.xpertize.africa",
      "lifecyclestage": "marketingqualifiedlead"
    }
  }

  const handleCaptchaChange = useCallback(
    (value) => {
      if(value && value.length > 0){
        setCaptchaValue(false);

      }
      // Do something with the captcha value like storing in the state
      console.log("Captcha value:", value);
      console.log("key value:", apiKey);
    },
    [setCaptchaValue]
  );
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    company: '',
    message: '',
    website: 'xecutive.xpertize.africa',
    lifecyclestage: 'marketingqualifiedlead'
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
     submitDataToHubSpot(formData);
    } catch (error) {
      console.error('Error creating contact:', error);
    }
  };
  // sending the data to the  hubspot backend
  const nom = (event) => {
    event.preventDefault();
    // send captchaValue to your server for verification
     if(event.target.value){
      formData.firstname = event.target.value;
     }
  }
  const prenom = (event) => {
    event.preventDefault();
    // send captchaValue to your server for verification
    if(event.target.value){
      formData.lastname = event.target.value;
     }
   }
  const email = (event) => {
    event.preventDefault();
    // send captchaValue to your server for verification
    if(event.target.value){
      formData.email = event.target.value;
     }
  } 
  const phone = (event) => {
    event.preventDefault();
    // send captchaValue to your server for verification
    if(event.target.value){
      formData.phone = event.target.value;
     }
  }
  const compagny = (event) => {
    event.preventDefault();
    // send captchaValue to your server for verification
    if(event.target.value){
      formData.company = event.target.value;
    }
  }
  const message = (event) => {
    event.preventDefault();
    // send captchaValue to your server for verification
    if(event.target.value){
      formData.message = event.target.value;
    }
  }
  const submitDataToHubSpot = async (data) => {
    let dataJson = JSON.stringify(formData);
    try {
      let dataReq = JSON.stringify({
        "properties": dataJson
      });
      
    const { data } = await axios.post(
      'https://api.therpocompany.com/api/hubspot-contact',
      objectToFormData(formData),
      {
        headers: {
            'Content-Type': 'application/json', 
        },
      }
    );
    setShow(true)
    setShowError(false)
    } catch (error) {
      console.error('Error creating contact:', error);
      setShowError(true)
      setShow(false)
    }
  };
  
  return (
    <>
   <Header/> 
    <div className="relative h-100 isolate bg-white" >
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto my-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute pt-5 inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
             
              <div
                className="absolute -left-56 top-[calc(100%-13rem)] transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                aria-hidden="true"
              >
                <div
                  className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-[#80caff] to-[#4f46e5] opacity-20"
                  style={{
                    clipPath:
                      'polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)',
                  }}
                />
              </div>
            </div>
            <h2 className="mt-5  font-bold tracking-tight textAmz">Contactez-nous</h2>
            <p className="mt-6 text-blacklg leading-8 text-blackgray-300">
            N'hésitez pas à prendre contact avec nous dès aujourd'hui afin que nous puissions vous aider à pourvoir ces postes de direction vacants avec des professionnels qualifiés.
            </p>
            <dl className="mt-10 space-y-4 text-blackbase leading-7 text-blackgray-300">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address Maroc</span>
                  <BuildingOffice2Icon className="h-7 w-6 black" aria-hidden="true" />
                </dt>
                <dd>
                Immeuble Casa Business Center, Lot Mandarona 300, N°2
                  <br />
                  5ème étage Sidi Maarouf, casablanca 20190
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address Sénégal</span>
                  <BuildingOffice2Icon className="h-7 w-6 black" aria-hidden="true" />
                </dt>
                <dd>
                Agora Center VDN Villa 7 Fann Mermoz, BP 
                  <br />
                  25582 Dakar-Fann, Dakar / Sénégal
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <PhoneIcon className="h-7 w-6 text-blackblack" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-blackwhite" href="tel:+212 5 29 02 96 40">
                  +212 5 29 02 96 40
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon className="h-7 w-6 text-blackblack" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-blackwhite" href="mailto:info@xpertize.africa">
                  info@xpertize.africa
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <form action="#" method="POST" className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label htmlFor="first-name" className="block text-blacksm font-semibold leading-6 text-blackwhite">
                  Nom
                </label>
                <div className="mt-2.5">
                  <input
                    type="text-black"
                    name="firstname"
                    id="first-name"
                    autoComplete="given-name"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-blackwhite shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-blacksm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-blacksm font-semibold leading-6 text-blackwhite">
                  Prénom
                </label>
                <div className="mt-2.5">
                  <input
                    type="text-black"
                    name="lastname"
                    id="last-name"
                    autoComplete="family-name"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-blackwhite shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-blacksm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-blacksm font-semibold leading-6 text-blackwhite">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-blackwhite shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-blacksm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="phone-number" className="block text-blacksm font-semibold leading-6 text-blackwhite">
                  Numéro de Téléphone                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    name="phone"
                    id="phone-number"
                    autoComplete="tel"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-blackwhite shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-blacksm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="compagny" className="block text-blacksm font-semibold leading-6 text-blackwhite">
                  Entreprise
                </label>
                <div className="mt-2.5">
                  <input
                    type="text-black"
                    name="company"
                    id="compagny"
                    autoComplete="compagny"
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-blackwhite shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-blacksm sm:leading-6"
                />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-blacksm font-semibold leading-6 text-blackwhite">
                  Message
                </label>
                <div className="mt-2">
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-blackwhite shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-blacksm sm:leading-6"
                    defaultValue={''}
                  />
                </div>
              </div>
              
              <ReCAPTCHA
          sitekey="6LdD_UAnAAAAAOaQ65e6qA1d2nxNlmgyu5Gq9CG6"
          onChange={handleCaptchaChange}
        />
            </div>
            <div className="mt-8 flex justify-end">
           
              <button
                onClick={handleSubmit}
                className="rounded-md bg-gray-500 px-3.5 py-2.5 text-blackcenter text-blacksm font-semibold text-blackwhite shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-500"
                disabled={captchaValue}
              >
                Envoyer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    {show && <Notification/>}
    {showError && <Error/>}
    </>
  )
}
