import React from 'react'
export default function Testimonial1() {
    return (
      <section className="relative mt-5 md:mt-10 isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="mx-auto max-w-2xl lg:max-w-6xl">
          
          <figure className="mt-10">
            <blockquote className="text-center text-xl font-semibold leading-8 textAmz lg:text-5xl  sm:text-2xl sm:leading-9">
              <p>
              Xecutive Africa a fourni les moyens nécessaires à la définition de nos besoins en recrutement et a accompagné notre équipe dans la sélection et la validation des profils adéquats.

              </p>
            </blockquote>
            <figcaption className="mt-10">
             
              <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                <div className="font-semibold text-gray-900"></div>
                
                <div className="text-gray-600 uppercase">directrice des ressources humaines - Leader de construction marocain</div>
              </div>
            </figcaption>
          </figure>
        </div>
      </section>
    )
  }
  