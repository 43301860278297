import React from "react";

const GridComponent = () => {
  return (
    <div className="text-center my-10">
      <h2 className="font-bold text-lg mt-5 mb-2 text-center	textAmz mx-3 ">Notre expertise dans le recrutement de cadres supérieurs s'étend à travers plusieurs industries. 
</h2>
<h2 className="font-bold text-lg mt-4 text-center	textAmz mx-4 ">
Découvrez nos références et les placements réussis que nous avons effectués pour nos partenaires !</h2>
      <div className="grid mt-10 grid-cols-1 gap-8 md:grid-cols-2 md:gap-8 text-left">
        <div id="DG" className="md:w-2/5 mx-auto w-10/12">
        <h2 className="text-lg my-5 font-semibold uppercase textAmz">Direction générale</h2>
          <ul className="mx-auto list-disc  capitalize">
            <li className="my-2"> ▪️ &nbsp;&nbsp; directeur général</li>
            <li className="my-2"> ▪️ &nbsp;&nbsp; général directeur</li>
            <li className="my-2"> ▪️ &nbsp;&nbsp; administrateurs gérant</li>
            <li className="my-2">▪️ &nbsp;&nbsp; gérant</li>
          </ul>
        </div>
        <div id="finance" className="md:w-2/5 mx-auto w-10/12	">
        <h2 className="text-lg my-5 font-semibold textAmz uppercase">Finance</h2>
          <ul className="mx-auto list-disc capitalize">
            <li className="my-2">▪️ &nbsp;&nbsp;Directeur Administratif & Financier</li>
            <li className="my-2">▪️ &nbsp;&nbsp;Directeur contrôle de Gestion</li>
            <li className="my-2">▪️ &nbsp;&nbsp;directeur comptable</li>
            <li className="my-2">▪️ &nbsp;&nbsp;manager audit</li>
          </ul>
        </div>
        <div id="sm" className="md:w-2/5 mx-auto w-10/12	">
        <h2 className="text-lg my-5 font-semibold  textAmz uppercase">Sales & Marketing</h2>
          <ul className="mx-auto list-disc capitalize">
            <li className="my-2">▪️ &nbsp;&nbsp;Directeur commercial</li>
            <li className="my-2">▪️ &nbsp;&nbsp;directeur marketing </li>
            <li className="my-2">▪️ &nbsp;&nbsp;directeur export</li>
            <li className="my-2">▪️ &nbsp;&nbsp;business Development manager</li>
            <li className="my-2"> ▪️ &nbsp;&nbsp;directeur communication</li>
          </ul>
        </div>
        <div id="sc" className="md:w-2/5 mx-auto w-10/12	">
        <h2 className="text-lg my-5 font-semibold textAmz uppercase">Supply Chain</h2>
          <ul className="mx-auto list-disc list-inside capitalize">
            <li className="my-2">▪️ &nbsp;&nbsp;Directeur logistiques</li>
            <li className="my-2">▪️ &nbsp;&nbsp;directeur achats</li>
            <li className="my-2">▪️ &nbsp;&nbsp;directeur approvisionnements</li>
            <li className="my-2">▪️ &nbsp;&nbsp;Directeur export</li>
          </ul>
        </div>
        <div id="rh" className="md:w-2/5 mx-auto w-10/12	">
        <h2 className="text-lg my-5 font-semibold textAmz uppercase">Ressources Humaines</h2>
          <ul className="mx-auto list-disc list-inside capitalize">
            <li className="my-2"> ▪️ &nbsp;&nbsp;Directeur des ressources humaines</li>
            <li className="my-2"> ▪️ &nbsp;&nbsp;directeur développement RH</li>
            <li className="my-2">▪️ &nbsp;&nbsp;Compensation & benefits manager</li>
            <li className="my-2">▪️ &nbsp;&nbsp;Happiness manager</li>
          </ul>
        </div>
        <div id="legal" className="md:w-2/5 mx-auto w-10/12	">
        <h2 className="text-lg my-5 font-semibold textAmz uppercase">Legal</h2>
          <ul className="mx-auto list-disc list-inside capitalize">
            <li className="my-2">▪️ &nbsp;&nbsp;Directeur juridique</li>
            <li className="my-2">▪️ &nbsp;&nbsp;avocat</li>
            <li className="my-2">▪️ &nbsp;&nbsp;directeur contentieux</li>
            <li className="my-2">▪️ &nbsp;&nbsp;compliance manager</li>
            <li className="my-2">▪️ &nbsp;&nbsp;regulatory manager</li>

          </ul>
        </div>
        <div id="IT" className="md:w-2/5 mx-auto w-10/12	">
        <h2 className="text-lg my-5 font-semibold textAmz uppercase">IT</h2>
          <ul className="mx-auto list-disc list-inside capitalize">
            <li className="my-2">▪️ &nbsp;&nbsp;Directeur Système d’informations</li>
            <li className="my-2">▪️ &nbsp;&nbsp;Cybersécurité manager</li>
            <li className="my-2">▪️ &nbsp;&nbsp;manager réseaux & télécoms</li>
          </ul>
        </div>
        <div id="eng" className="md:w-2/5 mx-auto w-10/12	">
        <h2 className="text-lg my-5 font-semibold textAmz uppercase">Engineering</h2>
          <ul className="mx-auto list-disc list-inside capitalize">
            <li className="my-2">▪️ &nbsp;&nbsp;directeur projets</li>
            <li className="my-2">▪️ &nbsp;&nbsp;MEP manager</li>
            <li className="my-2">▪️ &nbsp;&nbsp;directeur construction</li>
          </ul>
        </div>
        
      </div>
    </div>
  );
};

export default GridComponent;
