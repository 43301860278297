import React from 'react';
import Gallery from './Gallery'
import GradientComponent from './Special'
import Team from './Team';

const Section = ({ title }) => {
  return (
    <section className='w-100vw h-screen'>
      <div className='mt-4 flex flex-col items-center justify-center'>
      <h2 className='text-customText text-center font-assistant  text-3xl font-bold leading-tight uppercase focus-in-expand-fwd'>{title}</h2>
      <p className='my-3 text-customText text-center font-assistant font-semibold text-2xl focus-in-expand-fwd'>Investissez dans le futur et recrutez des dirigeants à la hauteur de vos ambitions avec <span className='text-3xl'>Xecutive Africa.</span></p>
      <div className='my-5 mx-10 text-slate-900	 text-base font-normal leading-normal focus-in-expand-fwd'>Xecutive Africa est spécialisée dans le recrutement de cadres supérieurs pour les entreprises de tous les secteurs d'activité. Nous sommes convaincus que les entreprises les plus performantes sont celles qui disposent des équipes les plus compétentes. C'est pourquoi nous mettons tout en œuvre pour identifier les meilleurs talents du marché et les mettre en relation avec les entreprises qui en ont besoin. Nous disposons d'une équipe de chasseurs de têtes expérimentés, qui utilisent des techniques de recherche avancées pour trouver les candidats les plus qualifiés pour chaque poste. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins spécifiques et leur fournir des candidats qui correspondent à la fois à leurs exigences techniques et à leur culture d'entreprise.<br /><br />

Nous somme distinguées par une approche personnalisée et d’un niveau d’engagement supérieur envers les besoins de nos partenaires.  Le recrutement de cadres supérieurs ne peut être efficace que s'il repose sur une compréhension approfondie des besoins de chaque entreprise. C'est pourquoi nous prenons le temps de comprendre les objectifs, les valeurs et la culture de chaque entreprise avec laquelle nous travaillons. Nous sommes également attentifs aux attentes des candidats que nous recrutons, en nous assurant qu'ils sont en mesure de trouver un poste qui correspond à leurs compétences et à leurs aspirations professionnelles. Le chasseur de tête est la première image de votre entreprise vis-à-vis des candidats, il est dont important de confier cette mission délicate à des experts aguerris qui ont le savoir faire et le réseau adéquat.</div>
</div>
<div className='flex flex-col '>
      <p className='text-customText  font-assistant mx-10 text-1xl font-bold leading-tight uppercase focus-in-expand-fwd'>Nos Références</p>
      <Gallery />
      
        </div>
        <GradientComponent />
        <Team />
    </section>
  );
};

export default Section;
