/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const people = [
  {
    name: 'Emma Dorsey',
    role: 'Senior Front-end Developer',
    imageUrl:
      'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    bio: 'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Emma Dorsey',
    role: 'Senior Front-end Developer',
    imageUrl:
      'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    bio: 'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Emma Dorsey',
    role: 'Senior Front-end Developer',
    imageUrl:
      'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    bio: 'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Emma Dorsey',
    role: 'Senior Front-end Developer',
    imageUrl:
      'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    bio: 'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  // More people...
]

export default function Team() {
  return (
    <div className=" mx-10 my-2 bg-white">
  <div class="my-component" style={{height: '70vh'}}>
  <div class="flex h-full">
    <div class="grow " style={{width: '70vw' , height: '45vh'}}>
      <h1 className="text-2xl font-bold uppercase textAmz">Niang Mouhamadou Lamine</h1>
      <h3 className="text-1xl font-bold my-2 textAmz">GM Executive Search Maroc / GM West Africa.</h3>
      <p className="mt-10">Capitalisant sur plus de 10 ans d'expérience dans le conseil RH et le Head Hunting, Mouhamadou Lamine Niang est un chasseur de talents bénéficiant d'un vaste réseau et d'une solide réputation sur le marché africain. Grâce à sa grande capacité à comprendre les attentes de ses partenaires et à son expertise approfondie, il accompagne de nombreux grands groupes internationaux dans l'identification et le recrutement de leurs futurs cadres et dirigeants. Il est le Directeur de la Business Unit Executive pour le Maroc et l’Afrique de l'Ouest au sein d'Xpertize Africa.</p>
         <div className="flex justify-between	 mx-5 mt-5 space-x-5">
          <div className="flex flex-col ">
          <h4 className="text-1xl font-bold textAmz">Domaine d’interventions</h4>
         <ul className="mt-5 custom-list ">
  <li className="capitalize"> ▪️ &nbsp;&nbsp;Direction générale</li>
  <li className="capitalize">▪️ &nbsp;&nbsp;Conseil d’administration</li>
  <li className="capitalize">▪️ &nbsp;&nbsp;Directeur filiale</li>
  <li className="capitalize">▪️ &nbsp;&nbsp;Administrateurs</li>

</ul>
<div className="flex items-center mt-3 space-x-4  p-4 rounded-md">
  <a href="https://www.linkedin.com/in/mouhamadou-lamine-niang-66a52270?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAA8G7zIBRfY3dSRZpGd3h2beovI1HJ67Mx0&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BOWkG6cdHQLiTWwNPD97%2BvQ%3D%3D" className="text-gray-600 hover:text-blue-600">
  <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
  </a>
  <a href="mailto:lamine@xpertize.africa" className="text-gray-600 hover:text-blue-600">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
</svg>

  </a>
  <a href="tel:+123456789" className="text-gray-600 hover:text-blue-600">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
</svg>

  </a>
</div>
            </div>

         <img id="team1" className="relative bottom-15 left-0 scale-up-center " src="https://ik.imagekit.io/kv2uqda6y/LN2.jpg?updatedAt=1691574616330" alt="L'Amine Nyang" style={{height: '45vh' , marginRight : '5rem'}}/>
         </div>
    </div>
    <div class="grow-0  flex flex-center" style={{width: '10vw'}}>
      <img className="opacity-0" src="https://res.cloudinary.com/daog54j96/image/upload/v1689528449/transparent_1_2_caenue.png" alt="" style={{height: '70vh' , zIndex : '50' , display: 'none'}}/>
    </div>
    <div class="grow mx-10" style={{width: '70vw' , height: '45vh'}}>
      <h1 className="text-2xl font-bold uppercase textAmz">fajar ibtissam</h1>
      <h3 className="text-1xl font-bold my-2 textAmz capitalize">Senior recruitment consultant  / Sales & marketing.</h3>
      <p className="mt-10">Chasseuse de tête, Ibtissam Fajar capitalise sur une solide expérience de plus de 7 ans en commerce international et développement des affaires afin de trouver les meilleurs talents pour ses clients. Passionnée par la création de partenariats durables et le recrutement de candidats hautement qualifiés, elle offre des solutions personnalisées à chaque client en s'appuyant sur son expertise en commerce et marketing, tout en travaillant avec intégrité. </p>
         <div className="flex justify-between	 mx-5 mt-5 space-x-5">
          <div className="flex flex-col ">
          <h4 className="text-1xl font-bold textAmz">Domaine d’interventions</h4>
         <ul className="mt-5 custom-list ">
  <li className="capitalize">▪️ &nbsp;&nbsp; Direction commerciale</li>
  <li className="capitalize">▪️ &nbsp;&nbsp;Direction Marketing</li>
  <li className="capitalize">▪️ &nbsp;&nbsp;Responsable Business Development</li>
  <li className="capitalize">▪️ &nbsp;&nbsp;Responsable Grands Comptes</li>

</ul>
<div className="flex items-center mt-5 space-x-4  p-4 rounded-md">
  <a href="https://www.linkedin.com/in/ibtissam-fajar-196aa0112?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABxIAwIBVs0stHgEITtfnalUL5RQ-5mM7xg&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3B2FPLCUIaTyKvQ9L%2FNJF5ow%3D%3D" className="text-gray-600 hover:text-blue-600">
  <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
  </a>
  <a href="mailto:ibtissam.f@xpertize.africa" className="text-gray-600 hover:text-blue-600">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
</svg>

  </a>
  <a href="tel:+123456789" className="text-gray-600 hover:text-blue-600">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
</svg>

  </a>
</div>

            </div>

         <img className="relative top-10 left-0 scale-up-center" src="https://ik.imagekit.io/kv2uqda6y/2.png?updatedAt=1691415833634" alt="Ibtissal Fajr" style={{height: '45vh' , marginRight : '5rem' }}/>
         </div>
    </div>
  </div>
</div>

  </div>
  
  )
}
