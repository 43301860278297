import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { NavLink } from "react-router-dom";

const people = [
    {
        name: '/references',
        title: 'Direction Générale',
        role: 'Xecutive Africa',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
          'https://ik.imagekit.io/kv2uqda6y/Direc%20G%C3%A9n%C3%A9.jpg?updatedAt=1691575456986',
      },
  
  {
    name: '/references',
    title: 'Finance',
    role: 'Xecutive Africa',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/2.png?updatedAt=1690986979656',
  },
  {
    name: '/references',
    title: 'Sales & Marketing',
    role: 'Xecutive Africa',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/pexels-vlada-karpovich-7433856.jpg?updatedAt=1691572959507',
  },
  {
    name: '/references',
    title: 'Supply Chain',
    role: 'Xecutive Africa',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/supplychain.jpg?updatedAt=1691574650112',
  },
  {
    name: '/references',
    title: 'Ressources Humaines',
    role: 'Xecutive Africa',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/pexels-rdne-stock-project-5922542.jpg?updatedAt=1691158631402',
  },
  {
    name: '/references',
    title: 'Legal',
    role: 'Xecutive Africa',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/5.png?updatedAt=1690986965547',
  },
  {
    name: '/references',
    title: 'IT',
    role: 'Xecutive Africa',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/IT.jpg?updatedAt=1691575312582',
  },
  {
    name: '/references#DG',
    title: 'Engineering',
    role: 'Xecutive Africa',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/engineering.jpg?updatedAt=1691574621014',
  },
  
  // More people...
]

export default function Cards() {
  return (
    <ul role="list" className="my-5 grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {people.map((person) => (
        <li
          key={person.email}
          className="w-full h-64 md:w-64 col-span-1 flex flex-col justify-center divide-y divide-gray-200 rounded-lg bg-white text-center shadow hover:scale-105"
          style={{ backgroundImage: `url('${person.imageUrl}')`, backgroundSize: 'cover' }}

        >
          <div className="image-overlay"></div>
            <NavLink to={person.name} className="h-full w-full flex">
          <div className=" mx-auto flex  flex-col	justify-center  p-8">
            <span className='font-bold text-2xl text-white z-50'>
            {person.title}
            </span>
          </div>
          <div>
            
          </div>
            </NavLink>
        </li>
      ))}
    </ul>
  )
}
