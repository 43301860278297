import React from "react";
import MyComponent from "./Logo"; 
const SplashScreen = () => {
  return (
    <div className="bg-white fade-in  flex justify-center bg-slate-900" style={{height:'100vh' , width:'100vw'}}>
      <img src="https://ik.imagekit.io/kv2uqda6y/Rectangle%2042%20(1).svg?updatedAt=1689935901015" alt="logo" style={{height:'80vh' , width:'60vw'}} />
    </div>
  );
};

export default SplashScreen;
