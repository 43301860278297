import { useState , useEffect } from 'react'
import { Dialog, Disclosure } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  ArrowPathIcon,
  CheckIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'
import Footer from './Footer'
import Header from './Header'
import Cards from './Cards'
const navigation = [
  { name: 'nos consultants', href: '#' },
  { name: 'nos référenes', href: '#' },
  { name: 'missions executives', href: '#' },
  { name: 'contactez-nous', href: '#' },
]
const features = [
  {
    name: 'Notre Mission.',
    description: "Xecutive Africa est spécialisée dans le recrutement de cadres supérieurs pour les entreprises de tous les secteurs d'activité. Nous sommes convaincus que les entreprises les plus performantes sont celles qui disposent des équipes les plus compétentes. C'est pourquoi nous mettons tout en œuvre pour identifier les meilleurs talents du marché et les mettre en relation avec les entreprises qui en ont besoin. Nous disposons d'une équipe de chasseurs de têtes expérimentés, qui utilisent des techniques de recherche avancées pour trouver les candidats les plus qualifiés pour chaque poste. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins spécifiques et leur fournir des candidats qui correspondent à la fois à leurs exigences techniques et à leur culture d'entreprise.",
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Notre Expertise.',
    description: "Nous somme distinguées par une approche personnalisée et d’un niveau d’engagement supérieur envers les besoins de nos partenaires. Le recrutement de cadres supérieurs ne peut être efficace que s'il repose sur une compréhension approfondie des besoins de chaque entreprise. C'est pourquoi nous prenons le temps de comprendre les objectifs, les valeurs et la culture de chaque entreprise avec laquelle nous travaillons. Nous sommes également attentifs aux attentes des candidats que nous recrutons, en nous assurant qu'ils sont en mesure de trouver un poste qui correspond à leurs compétences et à leurs aspirations professionnelles. Le chasseur de tête est la première image de votre entreprise vis-à-vis des candidats, il est dont important de confier cette mission délicate à des experts aguerris qui ont le savoir faire et le réseau adéquat",
    icon: LockClosedIcon,
  },
]
const tiers = [
  {
    name: 'Hobby',
    id: 'tier-hobby',
    href: '#',
    priceMonthly: '$19',
    description: "The perfect plan if you're just getting started with our product.",
    features: ['25 products', 'Up to 10,000 subscribers', 'Advanced analytics', '24-hour support response time'],
    featured: false,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    priceMonthly: '$49',
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      'Dedicated support representative',
      'Marketing automations',
      'Custom integrations',
    ],
    featured: true,
  },
]
const faqs = [
  {
    question: "Comment notre approche garantit-elle la confidentialité lors du processus de chasse de tête ?",
    answer:
      "Nous comprenons l'importance vitale de maintenir la discrétion à chaque étape de notre travail. Pour ce faire, nous mettons en place des protocoles sécurisés et des systèmes de contrôle stricts, nous limitons l'accès aux informations sensibles et nous n'engageons que les parties essentielles de notre équipe dans la gestion des données confidentielles. De plus, notre solide réseau de professionnels hautement qualifiés et notre solide réputation nous permettent de mener nos recherches avec une discrétion maximale.",
  },
  {
    question: "En quoi notre cabinet se distingue-t-il par le niveau d'attention personnalisée et de service offert à nos partenaires ?",
    answer:
      "Chacun de nos consultants est soigneusement alloué à un maximum de trois missions de placement à la fois. Cette approche nous permet de nous immerger profondément dans les besoins uniques de chaque client et de garantir une attention inégalée à chaque étape du processus. Notre engagement envers cette approche à faible volume garantit que nous pouvons offrir une expertise approfondie et une collaboration étroite, en maintenant la qualité de notre service à son plus haut niveau.",
  },
  {
    question: "Dans quels secteurs ou industries notre cabinet de chasse de tête possède-t-il une expertise particulière ?",
    answer:
      "Notre portefeuille reflète notre engagement envers la diversité et l'excellence. Nous entretenons des relations privilégiées avec des multinationales de divers secteurs d'activité, allant de la technologie à la santé, en passant par l'énergie et les services financiers. Tout en apportant une connaissance approfondie et une expérience solide à des fonctions de direction clés, couvrant des domaines tels que la finance, la direction générale, le marketing & les ventes, la logistique et bien plus encore.",
  },
  {
    question: "Des exemples de placements réussis de cadres supérieurs que notre cabinet a réalisés ?",
    answer:
      "Notre expérience diversifiée et notre réseau intercontinental nous ont permis de contribuer de manière significative aux succès de nos partenaires. Parmi nos réussites notables, on peut citer la sélection d'un directeur financier qui a permis de revitaliser la gestion financière et de stimuler la croissance au sein d'un environnement complexe et en constante évolution, ou encore le recrutement d'un directeur général qui, sous sa direction, a tracé la voie à une expansion et  une croissance record au sein du secteur de l'agro-industrie.",
  },
  // More questions...
]
const footerNavigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
}
const blogPosts = [
  {
    id: 1,
    title: 'directeur administratif et financier Leader de grande distribution',
    href: '#',
    date: 'Mar 18, 2023',
    datetime: '2023-03-18',
    category: { name: 'Article', href: '#' },
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/pexels-aleksandar-pasaric-1437493.jpg?updatedAt=1689512260815',
    preview:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam ',
    author: {
      name: 'Roel Aufderehar',
      imageUrl:
        'https://ik.imagekit.io/kv2uqda6y/Rectangle_89__2_.png?updatedAt=1689676631245',
      href: '#',
    },
    readingLength: '6 min',
  },
  {
    id: 2,
    title: 'Directeur Général multinationale de construction',
    href: '#',
    date: 'Mar 10, 2023',
    datetime: '2023-03-10',
    category: { name: 'Video', href: '#' },
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/pexels-sevenstorm-juhaszimrus-443383.jpg?updatedAt=1689512258736',
    preview:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.',
    author: {
      name: 'Brenna Goyette',
      imageUrl:
        'https://ik.imagekit.io/kv2uqda6y/Rectangle_89__2_.png?updatedAt=1689676631245',
      href: '#',
    },
    readingLength: '4 min',
  },
  {
    id: 3,
    title: 'financial controller géant d’assurance en Afrique',
    href: '#',
    date: 'Feb 12, 2023',
    datetime: '2023-02-12',
    category: { name: 'Case Study', href: '#' },
    imageUrl:
      'https://ik.imagekit.io/kv2uqda6y/pexels-mihai-vlasceanu-1400249.jpg?updatedAt=1689512254072',
    preview:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.',
    author: {
      name: 'Daniela Metz',
      imageUrl:
        'https://ik.imagekit.io/kv2uqda6y/Rectangle_89__2_.png?updatedAt=1689676631245',
      href: '#',
    },
    readingLength: '11 min',
  },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Secondlanding() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (imageLoaded) {
      const element = document.getElementById('myText'); // Replace 'elementId' with your element's ID
      if (element) {
      setTimeout(function (){
      } , 3000) // Replace 'yourClassName' with your desired class name
      }
    }
  }, [imageLoaded]);

  return (
    <div className="niceBg">
      {/* Header */}
     <Header /> 
      <main>
        {/* Hero section */}
        <div className="relative isolate overflow-hidden bg-white pb-16 pt-14 sm:pb-20">
          <img
            src="https://ik.imagekit.io/kv2uqda6y/DCS_4002_jdsaxz%201.png?updatedAt=1691400364804"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover scale-up-center"
            onLoad={() => setImageLoaded(true)}
          />
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="mx-auto max-w-full px-6 lg:px-8">
            <div className="mx-auto max-w-6xl py-32 sm:py-48 lg:py-56">
              <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-400 ring-1 ring-white/10 hover:ring-white/20">
               
                </div>
              </div>
              <div className="text-left">
                <h1 id="myText" className="liberty font-bold tracking-tight max-w-xs md:max-w-3xl leading-loose text-white  capitalize ">
                trouvez vos futurs cadres et
dirigeants avec xecutive africa
                </h1>
                
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  
                  
                </div>
              </div>
            </div>

            {/* Logo cloud */}
            <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
        </div>
        <div className="py-12 sm:pt-24 bg-white">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <p className="mt-2 text-4xl font-bold tracking-tight textAmz sm:text-5xl">
              Notre expertise s'étend dans tous les domaines, dans tous  les secteurs d'activité
              </p>
            </div>
            <p  className="mx-auto py-6 mt-6 text-left text-lg leading-8 text-gray-600">
            Xecutive Africa est spécialisée dans le recrutement de cadres supérieurs pour les entreprises de tous les secteurs d'activité. Nous sommes convaincus que les entreprises les plus performantes sont celles qui disposent des équipes les plus compétentes. C'est pourquoi nous mettons tout en œuvre pour identifier les meilleurs talents du marché et les mettre en relation avec les entreprises qui en ont besoin. Nous disposons d'une équipe de chasseurs de têtes expérimentés, qui utilisent des techniques de recherche avancées pour trouver les candidats les plus qualifiés pour chaque poste. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins spécifiques et leur fournir des candidats qui correspondent à la fois à leurs exigences techniques et à leur culture d'entreprise. Nous sommes distinguées par une approche personnalisée et d’un niveau d’engagement supérieur envers les besoins de nos partenaires. Le recrutement de cadres supérieurs ne peut être efficace que s'il repose sur une compréhension approfondie des besoins de chaque entreprise. C'est pourquoi nous prenons le temps de comprendre les objectifs, les valeurs et la culture de chaque entreprise avec laquelle nous travaillons. Nous sommes également attentifs aux attentes des candidats que nous recrutons, en nous assurant qu'ils sont en mesure de trouver un poste qui correspond à leurs compétences et à leurs aspirations professionnelles. Le chasseur de tête est la première image de votre entreprise vis-à-vis des candidats, il est donc important de confier cette mission délicate à des experts aguerris qui ont le savoir-faire et le réseau adéquat.
            </p>
            <Cards/>
          </div>
        </div>
         {/* Testimonial section */}
         <div className="relative z-10 mt-32 bg-gray-900 pb-20 sm:mt-56 sm:pb-24 xl:pb-0">
          <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
            <div className="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
              <div
                className="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>
          <div className="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
            <div className="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
              <div className="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
                <img
                  className="absolute inset-0 h-full w-full rounded-2xl bg-gray-800 object-cover shadow-2xl"
                  src="https://ik.imagekit.io/kv2uqda6y/shutterstock_1389400292.jpg?updatedAt=1691426217421"
                  alt="Team Xecutive Africa 2"
                />
              </div>
            </div>
            <div className="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
              <figure className="relative isolate pt-6 sm:pt-12">
                <svg
                  viewBox="0 0 162 128"
                  fill="none"
                  aria-hidden="true"
                  className="absolute left-0 top-0 -z-10 h-32 stroke-white/20 opacity-0	"
                >
                  <path
                    id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                    d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                  />
                  <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x={86} />
                </svg>
                <blockquote className="text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
                  <p id="blockquote">
                  Nous sommes convaincus que les entreprises les plus performantes sont celles qui disposent du capital humain le plus compétent. C'est pourquoi nous mettons tout en œuvre pour identifier, en toute confidentialité, les meilleurs talents du marché et les mettre en relation avec les entreprises
qui en ont besoin.
                  </p>
                </blockquote>
               
              </figure>
            </div>
          </div>
        </div>
        {/* Feature section */}
        <div className="mt-12 sm:mt-24 bg-white">
          <div className="mx-auto flex flex-col items-center	 max-w-7xl py-6 px-6 lg:px-8">
                <h1 className='text-2xl md:text-5xl my-2 font-bold capitalize textAmz'> Notre Equipe </h1>
                <p className='text-left text-lg leading-8 text-gray-600'> 
                Notre équipe de chasseurs de tête expérimentés se mobilise afin de garantir un service de qualité à nos clients. Grâce à notre vaste réseau et notre expertise multi-sectorielle, nous sommes en mesure d'adopter l’approche adéquate pour identifier les meilleurs talents et les convaincre de rejoindre votre projet. Nous nous engageons à fournir le meilleur conseil et à accompagner nos partenaires en tant que véritables Business Partner. Notre mission ne s'arrête pas à l'identification et à l'embauche des meilleurs talents. Nous nous assurons également de leur intégration réussie au sein de vos équipes. Nous travaillons en étroite collaboration avec vous pour comprendre vos besoins spécifiques, votre culture d'entreprise et vos objectifs à long terme.
 </p>
          </div>
          <div className="relative overflow-hidden pt-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <img
                src="https://res.cloudinary.com/daog54j96/image/upload/v1690975378/Photo_team_BG_white_qkj2tp.png"
                alt="App screenshot"
                className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
                width={2432}
                height={1442}
              />
              <div className="relative" aria-hidden="true">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-xecutive-background pt-[7%]" />
              </div>
            </div>
          </div>
          <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
            
          </div>
        </div>

        {/* FAQ section */}
        <div className="mx-auto my-32 max-w-7xl px-6 sm:mt-56 lg:px-8 ">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight textAmz">Questions fréquemment posées</h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">{faq.question}</span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                              <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>

        {/* create a devider */}
        
       
        <div className="py-6 sm:pt-10 bg-white">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto w-100 text-center">
              <h2 className="text-2xl my-7 md:text-5xl font-bold leading-7 capitalize textAmz">Exemples de missions Executives</h2>
              <p className="mt-5 text-left text-lg leading-8 text-gray-600 sm:text-lg">
              Notre approche pertinente en matière de chasse de têtes a fait ses preuves dans l'identification de futurs talents qui contribuent à la performance de votre entreprise. Grâce à notre processus individualisé, nous identifions les candidats qui possèdent à la fois les connaissances et les Softs Skills nécessaires pour obtenir les résultats attendus au sein de votre entreprise. En utilisant une stratégie de recrutement engageante, nos chasseurs de têtes travaillent avec diligence pour établir des relations transparentes et de confiance avec nos partenaires. N'hésitez pas à prendre contact avec nous dès aujourd'hui afin que nous puissions vous aider à pourvoir ces postes de direction vacants avec des professionnels qualifiés.

Notre service Xecutive Search vous permettra de recruter les meilleurs candidats pour vos postes stratégiques. Nous mettons à votre disposition l'expertise de nos chasseurs de têtes de premier plan, qui travailleront avec diligence pour identifier et convaincre les meilleurs talents de vous rejoindre. Nous rationalisons le processus de recrutement en vous offrant une assistance dédiée tout au long du processus, afin que vous puissiez vous concentrer sur vos enjeux stratégiques.
              </p>
              <div className="relative">
              <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                <h2 className="text-lg font-semibold text-cyan-600"></h2>
                
              </div>
              <div className="mx-auto mt-12 grid max-w-md gap-12 px-6 sm:max-w-lg lg:max-w-7xl lg:grid-cols-3 lg:px-8">
                {blogPosts.map((post) => (
                  <div key={post.id} className="flex flex-col overflow-hidden rounded-lg shadow-lg hover:scale-105">
                    <div className="flex-shrink-0">
                      <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                    </div>
                    <div className="flex flex-1 flex-col justify-between bg-white p-4">
                      <div className="flex-1">
                        
                        <a href={post.href} className="mt-2 block">
                          <p className="text-xl font-semibold uppercase text-gray-900">{post.title}</p>
                          <p className="mt-3 text-base text-justify text-gray-500">{post.preview}</p>
                        </a>
                      </div>
                      <div className="mt-6 flex items-center" style={{justifyContent:'space-between'}}>
                        <div className="flex-shrink-0">
                          <a href={post.author.href} className="flex">
                            <time dateTime={post.datetime} style={{margin:'12px'}}>{post.date}</time>
                          </a>
                        </div>
                        <div className="ml-3">
                          
                          <div className="flex  text-sm text-gray-500">
                           
                            <span aria-hidden="true">&middot;</span>
                            <button
    class="mt-2 text-blue-500 font-semibold hover:underline focus:outline-none"
    
  >
    Savoir plus
  </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            </div>
            
          </div>
        </div>
       
         
      </main>

      {/* Footer */}
      <Footer  />
    </div>
  )
}
