import React from "react";  
// need compoanent to have headen in the middle of the page and text on the center above the titile 

export default function Chasseur() {
  return (
    <div className="white-bg">
        <div className="mx-auto max-w-full py-2 px-4 sm:px-6 lg:px-4">
            <div className="text-center">
                <p className="mt-2 text-3xl font-extrabold textAmz tracking-tight sm:text-4xl capitalize">
                Nos chasseurs
                </p>
                <p className="my-5 max-w-6xl mx-auto text-xl text-slate-500 text-center">
                Grâce à notre savoir-faire, notre expertise et notre expérience, nos chasseurs de tête sont des partenaires d'excellence pour votre entreprise. Nous travaillons en étroite collaboration avec vos équipes de manière transparente et confidentielle pour vous aider à identifier rapidement vos futurs Cadres et Dirigeants .
                </p>
            </div>
            </div>
            </div>
    );
}
