import React from "react"
export default function Testemonial2() {
    return (
      <section className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 textAmz">
                  <p className="text-center font-bold ">
                  Xecutive Africa de part sa connaissance de l’environnement socio-culturel Ouest Africain et de la qualité de son réseau, a su dénicher les directeurs généraux pour le lancement de 3 filiales en Afrique de l’Ouest

                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                 
                  <div className="text-base">
                    <div className="font-semibold text-gray-900"></div>
                    <div className="mt-1 md:ml-16 text-center text-gray-500 uppercase">Président directeur général - industrie lourde</div>
                  </div>
                </figcaption>
              </figure>
            </div>
            <div className="flex flex-col border-t border-gray-900/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 textAmz">
                  <p className="text-center font-bold text-xl	">

                  Xecutive Africa a pu clôturer 3 besoins de postes critiques pour notre entreprise. J’étais très satisfaite à la fois par la rapidité du retour et la qualité des profils présentés.

                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                 
                  <div className="text-base">
                    <div className="font-semibold text-gray-900"></div>
                    <div className="mt-1 ml-16 text-center text-gray-500 uppercase">senior talent acquisition manager multinationale fmcg</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>
    )
  }
  